var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', _vm._l(_vm.items, function (item) {
    return _c(_vm.resolveNavItemComponent(item), {
      key: item.header || item.title,
      tag: "component",
      attrs: {
        "item": item
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }