import { permissionsConstant } from "@/helpers/permissionsConstant";
import leadManagementNavigation from "./leadManagementNavigation";

export default [
  {
    title: "Dashboard",
    route: "admin-dashboard",
    icon: "HomeIcon",
    gate: permissionsConstant.DASHBOARD_ACCESS,
  },
  {
    title: "Find Leads ",
    route: "admin-lead-generator",
    icon: "MapPinIcon",
    gate: permissionsConstant.LEAD_GENERATOR_ACCESS,
    // children: [
    //   {
    //     title: "Google Leads",
    //     route: "admin-lead-generator",
    //     gate: permissionsConstant.LEAD_GENERATOR_ACCESS,
    //     icon: "MapIcon",
    //   }
    // ],
  },
  {
    title: "All Leads",
    route: "all-leads-info",
    icon: "BookOpenIcon",
    gate: permissionsConstant.LIST_OF_LEAD_ACCESS,
  },

  /* {
    title: "Roles",
    route: "admin-roles",
    icon: "UserCheckIcon",
    gate: permissionsConstant.ROLES_ACCESS,
  }, */
  /* {
    title: "Users",
    route: "admin-users",
    icon: "UsersIcon",
    gate: permissionsConstant.USERS_ACCESS,
  }, */
  /* {
    title: "Designation",
    route: "admin-designation",
    icon: "AwardIcon",
    gate: permissionsConstant.DESIGNATION_ACCESS,
  }, */

  {
    title: "Campaigns",
    route: "admin-campaigns",
    icon: "PenToolIcon",
    gate: permissionsConstant.CAMPAIGN_ACCESS,
  },

  {
    title: "All Users",
    route: "all-users",
    icon: "UsersIcon",
    gate: permissionsConstant.USERS_ACCESS,
  },

  {
    title: "Subscription",
    icon: "DollarSignIcon",
    gate: permissionsConstant.LIST_OF_LEAD_ACCESS,
    children: [
      {
        title: "Pricing Plan",
        route: "pricing-plan",
        gate: permissionsConstant.LIST_OF_LEAD_ACCESS,
      },
      {
        title: "Payment History",
        route: "payment-histories",
        gate: permissionsConstant.LIST_OF_LEAD_ACCESS,
      },
    ],
  },

  ...leadManagementNavigation,
];
