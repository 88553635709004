import { permissionsConstant } from "@/helpers/permissionsConstant";

export default [
  // {
  //   title: "Lead Management",
  //   icon: "SettingsIcon",
  //   gate: permissionsConstant.LEAD_MANAGEMENT_ACCESS,
  //   children: [
  //     {
  //       title: "Type",
  //       route: "admin-lead-types",
  //       icon: "CodesandboxIcon",
  //       gate: permissionsConstant.LEAD_TYPE_ACCESS
  //     }
  //   ],
  // },
];
