var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "nav-item nav-search"
  }, [_c('a', {
    staticClass: "nav-link nav-link-search",
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function click($event) {
        _vm.showSearchBar = true;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon",
      "size": "21"
    }
  })], 1), _c('div', {
    staticClass: "search-input",
    class: {
      open: _vm.showSearchBar
    }
  }, [_c('div', {
    staticClass: "search-input-icon"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _vm.showSearchBar ? _c('b-form-input', {
    attrs: {
      "placeholder": "Explore Project X",
      "autofocus": "",
      "autocomplete": "off"
    },
    on: {
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])) { return null; }
        return _vm.increaseIndex(false);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"])) { return null; }
        return _vm.increaseIndex($event);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        _vm.showSearchBar = false;
        _vm.resetsearchQuery();
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.suggestionSelected($event);
      }],
      "blur": function blur($event) {
        $event.stopPropagation();
        _vm.showSearchBar = false;
        _vm.resetsearchQuery();
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "search-input-close",
    on: {
      "click": function click($event) {
        _vm.showSearchBar = false;
        _vm.resetsearchQuery();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "XIcon"
    }
  })], 1), _c('vue-perfect-scrollbar', {
    staticClass: "search-list search-list-main scroll-area overflow-hidden",
    class: {
      show: _vm.searchQuery
    },
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "ul"
    }
  }, _vm._l(_vm.filteredData, function (suggestion_list, grp_name, grp_index) {
    return _c('li', {
      key: grp_index,
      staticClass: "suggestions-groups-list"
    }, [_c('p', {
      staticClass: "suggestion-group-title"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.title(grp_name)) + " ")])]), _c('ul', [_vm._l(suggestion_list, function (suggestion, index) {
      return _c('li', {
        key: index,
        staticClass: "suggestion-group-suggestion cursor-pointer",
        class: {
          'suggestion-current-selected': _vm.currentSelected === "".concat(grp_index, ".").concat(index)
        },
        on: {
          "mouseenter": function mouseenter($event) {
            _vm.currentSelected = "".concat(grp_index, ".").concat(index);
          },
          "mousedown": function mousedown($event) {
            $event.preventDefault();
            return _vm.suggestionSelected(grp_name, suggestion);
          }
        }
      }, [grp_name === 'pages' ? _c('b-link', {
        staticClass: "p-0"
      }, [_c('feather-icon', {
        staticClass: "mr-75",
        attrs: {
          "icon": suggestion.icon
        }
      }), _c('span', {
        staticClass: "align-middle"
      }, [_vm._v(_vm._s(suggestion.title))])], 1) : grp_name === 'files' ? [_c('div', {
        staticClass: "d-flex align-items-center"
      }, [_c('b-img', {
        staticClass: "mr-1",
        attrs: {
          "src": suggestion.icon,
          "height": "32"
        }
      }), _c('div', [_c('p', [_vm._v(_vm._s(suggestion.file_name))]), _c('small', [_vm._v("by " + _vm._s(suggestion.from))])]), _c('small', {
        staticClass: "ml-auto"
      }, [_vm._v(_vm._s(suggestion.size))])], 1)] : grp_name === 'contacts' ? [_c('div', {
        staticClass: "d-flex align-items-center"
      }, [_c('b-avatar', {
        staticClass: "mr-1",
        attrs: {
          "src": suggestion.img,
          "size": "32"
        }
      }), _c('div', [_c('p', [_vm._v(_vm._s(suggestion.name))]), _c('small', [_vm._v(_vm._s(suggestion.email))])]), _c('small', {
        staticClass: "ml-auto"
      }, [_vm._v(_vm._s(suggestion.time))])], 1)] : _vm._e()], 2);
    }), !suggestion_list.length && _vm.searchQuery ? _c('li', {
      staticClass: "suggestion-group-suggestion no-results"
    }, [_c('p', [_vm._v("No Results Found.")])]) : _vm._e()], 2)]);
  }), 0)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }