var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item', {
    on: {
      "click": function click($event) {
        _vm.skin = _vm.isDark ? 'light' : 'dark';
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": "".concat(_vm.isDark ? 'Sun' : 'Moon', "Icon")
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }